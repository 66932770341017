import { FaLock, FaLockOpen, FaInfoCircle } from 'react-icons/fa'
import styled from 'styled-components'
import Tippy from '@tippyjs/react';

export const LockIcon = styled(FaLock)`
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: grey;
  display: block;
`

export const LockOpenIcon = styled(FaLockOpen)`
  height: 18px;
  width: 18px;
  cursor: pointer;
  color: grey;
  display: none;
`

export const LockIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 101;

  // &:hover ${LockIcon} {
  //   display: none;
  // }

  // &:hover ${LockOpenIcon} {
  //   display: block;
  // }
`

export const InfoIcon = styled(FaInfoCircle)`
  height: 20px;
  width: 20px;
  cursor: pointer;
  color: grey;
  display: block;
`

export const InfoIconWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 16px;
  z-index: 101;
`

export const TooltipWrapper = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const UnauthorizedCard = styled.div`
  position: relative;
  border-radius: 20px;
  text-decoration: none;
  margin: 10px;
  // min-width: 250px;
  width: 240px;
  // max-width: 250px;
  padding: 12px;
  background-color: var(--color-background-report-card);
  filter: grayscale(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // z-index: 1;

  &:hover {
    opacity: 0.5;
  }

  & img {
    border-radius: 15px;
    width: 100%;
    height: 120px;
    object-fit: scale-down; 
    filter: grayscale(100%);
  }

  & p {
    // background-color: var(--color-orange);
    background-color: #808080;
    font-size: 16px;
    margin: 10px 0;
    font-weight: 400;
    color: var(--color-white);
    padding: 10px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 50px;
    position: relative;
  }

  &:hover p {
    max-height: none;
  }

  & p:hover::after {
    content: attr(data-content);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: var(--color-orange);
    color: white;
    padding: 10px;
    border-radius: 10px;
    white-space: normal;
    // z-index: 2;
    overflow: visible;
    text-overflow: initial;
    transition: height 0.3s;
  }

  &:hover p {
    max-height: 200px;
    overflow: visible;
  }

  // @media (max-width: 500px) {
  //   width: 250px;

  //   & img {
  //     height: 120px;
  //   }

  //   & p {
  //     font-size: 14px;
  //   }
  }
`

export const IconTippy = styled(Tippy)`
  background: var(--color-orange);
  font-size: 13px;
  color: var(--color-background-report-card);
`